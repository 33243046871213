import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { Observable } from 'rxjs';

@Injectable()
export class EnvironmentGuard {
    private readonly environment = inject(ENVIRONMENT);

    canActivate(
        next: ActivatedRouteSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const production = next.data.production ?? true;

        return !this.environment.production || production;
    }
}
