import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserCapabilities } from '@modules/core';
import { UserCapabilitiesService } from '@modules/core/services/user-capabilites.service';
import { Observable, first, map, tap } from 'rxjs';
import { forbiddenAccess } from '../routes';

@Injectable()
export class CapabilitiesGuard {
    constructor(
        private router: Router,
        private userCapabilitiesService: UserCapabilitiesService,
    ) {}

    // at least one data.capabilities must match currentUser.capabilites
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const atLeastOne: (keyof UserCapabilities)[] =
            route.data.capabilities || [];

        return this.userCapabilitiesService.userCapabilities$.pipe(
            first(),
            map((userCapabilities) =>
                atLeastOne.some((c) => (userCapabilities || {})[c]),
            ),
            tap((canActivate) => {
                if (!canActivate) {
                    this.router.navigate([`/${forbiddenAccess}`], {
                        skipLocationChange: true,
                    });
                }
            }),
        );
    }
}
