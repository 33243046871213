import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
    ConfigurationService,
    InstalledFeatures,
} from '@interacta-shared/data-access-configuration';
import { forbiddenAccess } from '@modules/app-routing/routes';
import { Observable } from 'rxjs';
@Injectable()
export class EnvironmentInfoGuard {
    constructor(
        private router: Router,
        private configurationService: ConfigurationService,
    ) {}

    // at least one data.installedFeatures must match environmentInfo.installedFeatures
    canActivate(
        route: ActivatedRouteSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        const atLeastOne: (keyof InstalledFeatures)[] =
            route.data.installedFeatures || [];
        const environmentInfo = this.configurationService.getEnvironmentInfo();
        if (
            environmentInfo &&
            atLeastOne.some((c) => (environmentInfo.installedFeatures || {})[c])
        ) {
            return true;
        }
        this.router.navigate([`/${forbiddenAccess}`], {
            skipLocationChange: true,
        });
        return false;
    }
}
